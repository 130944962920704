import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PolygonButton } from '../buttons';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { icons } from '../../utils/constants/icons';
import * as Styled from './styled.components';
import { truncateStr } from '../../utils/helpers';
import {
    connectWalletRequest,
    logoutWalletRequest
} from '../../actions/web3Actions';

const Sidebar = ({ toggleSiderbar }) => {
    const { isConnected, ownerAddress } = useSelector((state) => state.web3);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();

    const navigateTo = (path) => {
        navigate(path);
        toggleSiderbar();
    };

    const connectHandler = () => {
        trackEvent({
            category: 'navbar',
            action: 'click-connect'
        });
        dispatch(connectWalletRequest());
    };

    const logoutHandler = () => {
        trackEvent({
            category: 'navbar',
            action: 'click-logout'
        });
        dispatch(logoutWalletRequest());
    };

    return (
        <Styled.Container>
            <Styled.CloseIconContainer onClick={toggleSiderbar}>
                {icons.closeIcon}
            </Styled.CloseIconContainer>
            <Styled.NavItems>
                <Styled.NavItem
                    onClick={() => navigateTo('/')}
                    className={pathname === '/' ? 'active' : ''}
                >
                    Home
                </Styled.NavItem>
                <Styled.NavAnchor
                    href="https://docs.zitherin.com"
                    target="_blank"
                    className={''}
                >
                    Docs
                </Styled.NavAnchor>
                <Styled.NavAnchor
                    href="https://discord.gg/mD4YGpC2tE"
                    target="_blank"
                    className={''}
                >
                    Discord
                </Styled.NavAnchor>
                {/* <Styled.NavItem
          onClick={() => navigateTo("/marketplace")}
          className={pathname === "/marketplace" ? "active" : ""}
        >
          Marketplace
        </Styled.NavItem>
        <Styled.NavItem
          onClick={() => navigateTo("/mint")}
          className={pathname === "/mint" ? "active" : ""}
        >
          Mint
        </Styled.NavItem> */}
                {isConnected && (
                    <Styled.NavItem
                        onClick={() => navigateTo('/activities')}
                        className={pathname === '/activities' ? 'active' : ''}
                    >
                        Activities
                    </Styled.NavItem>
                )}
                {!isConnected && (
                    <Styled.NavBtnContainer>
                        <PolygonButton onClick={connectHandler}>
                            Connect Wallet
                        </PolygonButton>
                    </Styled.NavBtnContainer>
                )}
                {isConnected && ownerAddress !== '' && (
                    <Styled.NavBtnContainer>
                        <PolygonButton onClick={logoutHandler}>
                            <Styled.UserName>
                                {truncateStr(ownerAddress, 15)}
                            </Styled.UserName>
                        </PolygonButton>
                    </Styled.NavBtnContainer>
                )}
            </Styled.NavItems>
        </Styled.Container>
    );
};

export default Sidebar;
