import { icons } from "./icons";
export const faqData = [
  {
    id: 1,
    icon: icons.add,
    question:
      "What is Zitherin?",
    answer:
      "An RPG adventure game built on blockchain. Mint your Heroes. Equip your gear. Battle your way through dungeons and arenas.",
  },
  {
    id: 2,
    icon: icons.add,
    question: "What is a Hero?",
    answer: "A Hero is the core way that players interact with the world of Zitherin. A Hero is an NFT. Players can equip their Heroes with gear, send them to battle in dungeons, and fight other Heroes in PvP arenas.",
  },
  {
    id: 3,
    icon: icons.add,
    question:
      "What are dungeons?",
    answer: "Dungeons are NFTs which contain treasures, monsters, and more. Players can mint dungeons, and send their Heroes to battle. Players earn rewards by defeating dungeons - gear, gold, crafting materials, and even new Heroes.",
  },
  {
    id: 4,
    icon: icons.add,
    question: "What is the mint price of a Hero?",
    answer: "The mint price has not yet been set.",
  },
  {
    id: 5,
    icon: icons.add,
    question: "When does minting begin?",
    answer: "A minting date for Heroes has not been set. The first public mint will be Heroes. Minting dungeons and arenas will be in subsequent updates.",
  },
  {
    id: 6,
    icon: icons.add,
    question: "Where can I find more information?",
    answer: "Check out our docs over at https://docs.zitherin.com, and drop by our Discord to chat!",
  }
];
