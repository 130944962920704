import React from 'react';
import * as Styled from './styles';
import { roadmapData } from '../../utils/constants/roadmap';
import { icons } from '../../utils/constants/icons';
const LandingRoadmapMobile = () => {
    return (
        <Styled.SectionThree>
            <Styled.HeroContainerTwo>
                <Styled.HeroTitleMedium>Roadmap</Styled.HeroTitleMedium>
            </Styled.HeroContainerTwo>
            <Styled.RoadmapMobileContainer>
                {roadmapData.map((item) => (
                    <Styled.RoadMapMobileItemWrapper key={item.id}>
                        {!item.pending && (
                            <Styled.OutestMobileCircle>
                                <Styled.OuterMobileCircle>
                                    <Styled.InnerMobileCircle>
                                        <Styled.Icon>
                                            {item.inProgress
                                                ? icons.hourglass
                                                : icons.check}
                                        </Styled.Icon>
                                    </Styled.InnerMobileCircle>
                                </Styled.OuterMobileCircle>
                            </Styled.OutestMobileCircle>
                        )}
                        <Styled.RoadMapMobileItem>
                            <Styled.RoadmapMobileTitle>
                                {item.title}
                            </Styled.RoadmapMobileTitle>
                            <Styled.RoadmapMobileText>
                                {item.text}
                            </Styled.RoadmapMobileText>
                            <Styled.RoadmapMobileSubtitle>
                                {item.subtitle}
                            </Styled.RoadmapMobileSubtitle>
                        </Styled.RoadMapMobileItem>
                    </Styled.RoadMapMobileItemWrapper>
                ))}
            </Styled.RoadmapMobileContainer>
        </Styled.SectionThree>
    );
};

export default LandingRoadmapMobile;
