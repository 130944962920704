const initState = {
    filterType: '',
    ownAssetLabels: [],
    nonOwnAssetLabels: [],
    filteredOwnAssets: [],
    filteredNonOwnAssets: []
};

const filterReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_FILTER_TYPE':
            return { ...state, filterType: action.payload };
        case 'SET_OWN_ASSETS_LABELS':
            return { ...state, ownAssetLabels: action.payload };
        case 'SET_NON_OWN_ASSETS_LABELS':
            return { ...state, nonOwnAssetLabels: action.payload };
        case 'SET_FILTERED_OWN_ASSETS':
            return { ...state, filteredOwnAssets: action.payload };
        case 'SET_FILTERED_NON_OWN_ASSETS':
            return { ...state, filteredNonOwnAssets: action.payload };
        default:
            return state;
    }
};

export default filterReducer;
