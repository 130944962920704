const initState = {
    isConnected: false,
    ownerAddress: '',
    errorMessage: '',
    provider: {},
    signer: {},
    mintedTokens: [],
    mintError: '',
    isMinting: false
};

const web3Reducer = (state = initState, action) => {
    let errorMessage = '';
    switch (action.type) {
        case 'CONNECT_WALLET_REQUEST':
            return state;
        case 'CONNECT_WALLET_SUCCESS':
            // this is done after ensuring the network is correct
            return {
                ...state,
                isConnected: true,
                ownerAddress: action.payload.ownerAddress,
                provider: action.payload.provider,
                signer: action.payload.signer
            };
        case 'CONNECT_WALLET_FAILURE':
            if (typeof action.payload !== 'string') {
                errorMessage = action.payload.message;
            } else {
                errorMessage = action.payload;
            }

            return {
                ...state,
                isConnected: false,
                errorMessage: errorMessage
            };
        case 'LOGOUT_WALLET_REQUEST':
            return {
                ...initState
            };
        case 'MINT_REQUEST': {
            return {
                ...state,
                mintedTokens: [],
                mintError: '',
                isMinting: true
            };
        }
        case 'MINT_SUCCESS':
            return {
                ...state,
                isMinting: false
            };
        case 'MINT_CLEAR_ERROR':
            return {
                ...state,
                mintError: ''
            };
        case 'MINT_FAILURE':
            debugger;
            if (typeof action.payload !== 'string') {
                errorMessage = action.payload.message;
            } else {
                errorMessage = action.payload;
            }

            return {
                ...state,
                mintError: errorMessage
            };
        default:
            return state;
    }
};

export default web3Reducer;
