import { takeEvery } from 'redux-saga/effects';
import {
    connectWalletSaga,
    mintSaga,
    switchNetworkSaga,
    addNetworkSaga,
    fetchMintedTokensSaga
} from './web3Sagas';
import { showToastSaga } from './toastSagas';

export default function* watchWalletConnectSaga() {
    yield takeEvery('CONNECT_WALLET_REQUEST', connectWalletSaga);
    yield takeEvery('ADD_NETWORK_REQUEST', addNetworkSaga);
    yield takeEvery('NETWORK_ADD_SUCCESS', switchNetworkSaga);
    // yield takeEvery('ACCOUNT_CHANGED', switchNetworkSaga);
    yield takeEvery('NETWORK_CHANGED', switchNetworkSaga);
    yield takeEvery('MINT_REQUEST', mintSaga);
    yield takeEvery('FETCH_MINTED_TOKENS_REQUEST', fetchMintedTokensSaga);

    // Toast saga
    yield takeEvery('SHOW_TOAST', showToastSaga);
}
