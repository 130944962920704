export const connectWalletRequest = (payload) => {
    return {
        type: 'CONNECT_WALLET_REQUEST',
        payload: payload
    };
};

export const connectWalletSuccess = (payload) => {
    return {
        type: 'CONNECT_WALLET_SUCCESS',
        payload: payload
    };
};

export const connectWalletFailure = (payload) => {
    return {
        type: 'CONNECT_WALLET_FAILURE',
        payload: payload
    };
};

export const logoutWalletRequest = (payload) => {
    return {
        type: 'LOGOUT_WALLET_REQUEST',
        payload: payload
    };
};

export const mintRequest = (payload) => {
    return {
        type: 'MINT_REQUEST',
        payload
    };
};

export const clearMintError = () => {
    return {
        type: 'MINT_CLEAR_ERROR'
    };
};
