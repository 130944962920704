import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import * as Styled from './styled.components';
import { Search, FilterButton, Asset, Token } from '../../../components';
import {
    openFilterModal,
    openNonOwnAssetModal,
    openOwnAssetModal,
    setFilterType,
    setNonOwnAssetModalData,
    setOwnAssetModalData
} from '../../../actions';
import { carouselBreakpoints } from '../../../utils/constants/carousel';
import 'react-loading-skeleton/dist/skeleton.css';

export const MarketplaceDesktop = ({
    isLoading,
    tokens,
    ownAssetData,
    nonOwnAssetData,
    selectedToken,
    dropEndHandler
}) => {
    const [searchValue, setSearchValue] = useState({
        ownAssets: '',
        nonOwnAssets: ''
    });
    const dispatch = useDispatch();
    const { filteredOwnAssets, filteredNonOwnAssets } = useSelector(
        (state) => state.filter
    );

    const handleNonOwnAssetClick = (data) => {
        dispatch(setNonOwnAssetModalData(data));
        dispatch(openNonOwnAssetModal());
    };

    const handleOwnAssetClick = (data) => {
        dispatch(setOwnAssetModalData(data));
        dispatch(openOwnAssetModal());
    };

    const handleOwnAssetFilterClick = () => {
        dispatch(setFilterType('OWN_ASSETS'));
        dispatch(openFilterModal());
    };

    const handleNonOwnAssetFilterClick = () => {
        dispatch(setFilterType('NON_OWN_ASSETS'));
        dispatch(openFilterModal());
    };

    return (
        <Styled.Container>
            <Styled.Main>
                <Styled.TokensContainer>
                    <Styled.Title>Tokens</Styled.Title>
                    {isLoading && (
                        <Styled.TokensWrapper>
                            {[1, 2, 3, 4, 5].map((item) => (
                                <Token key={item} isLoading={isLoading} />
                            ))}
                        </Styled.TokensWrapper>
                    )}

                    {!isLoading && (
                        <Styled.TokensWrapper>
                            {tokens.length > 0
                                ? tokens.map((item) => {
                                      return (
                                          <Token
                                              key={item.token_id}
                                              token={item}
                                              isLoading={
                                                  selectedToken &&
                                                  selectedToken.token_id ===
                                                      item.token_id
                                              }
                                          />
                                      );
                                  })
                                : 'No Tokens Found'}
                        </Styled.TokensWrapper>
                    )}
                </Styled.TokensContainer>
                <Styled.Separator />
                <Styled.Content>
                    <Styled.BuyAssetsContainer>
                        <Styled.Header>
                            <Styled.Group>
                                <Styled.Title>Assets to Buy</Styled.Title>
                                <Search
                                    name="nonOwnAssets"
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                />
                            </Styled.Group>
                            <FilterButton
                                onClick={handleNonOwnAssetFilterClick}
                            >
                                Filter
                            </FilterButton>
                        </Styled.Header>
                        {isLoading && (
                            <Styled.LoadingAssetContainer>
                                {[1, 2, 3, 4].map((key) => (
                                    <Asset key={key} isLoading={isLoading} />
                                ))}
                            </Styled.LoadingAssetContainer>
                        )}
                        {!isLoading && (
                            <Styled.AssetContainer>
                                {nonOwnAssetData.length > 0 ? (
                                    <Carousel
                                        swipeable={true}
                                        draggable={false}
                                        infinite={false}
                                        keyBoardControl={true}
                                        shouldResetAutoplay={false}
                                        responsive={carouselBreakpoints}
                                        autoPlay={false}
                                        removeArrowOnDeviceType={[
                                            'tabletSmall'
                                        ]}
                                        itemClass="carousel-item-margin"
                                    >
                                        {nonOwnAssetData
                                            .filter(
                                                (item) =>
                                                    filteredNonOwnAssets.length ===
                                                        0 ||
                                                    filteredNonOwnAssets.includes(
                                                        item.trait_name.toLowerCase()
                                                    )
                                            )
                                            .filter(
                                                (item) =>
                                                    searchValue.nonOwnAssets ===
                                                        '' ||
                                                    item.trait_name
                                                        .toLowerCase()
                                                        .includes(
                                                            searchValue.nonOwnAssets.toLowerCase()
                                                        ) ||
                                                    item.value
                                                        .toLowerCase()
                                                        .includes(
                                                            searchValue.nonOwnAssets.toLowerCase()
                                                        )
                                            )
                                            .map((item) => (
                                                <Asset
                                                    key={item.chain_id}
                                                    asset={item}
                                                    onClick={
                                                        handleNonOwnAssetClick
                                                    }
                                                    dropEndHandler={
                                                        dropEndHandler
                                                    }
                                                />
                                            ))}
                                    </Carousel>
                                ) : (
                                    'No Assets Found'
                                )}
                            </Styled.AssetContainer>
                        )}
                    </Styled.BuyAssetsContainer>
                    <Styled.OwnAssetsContainer>
                        <Styled.Header>
                            <Styled.Group>
                                <Styled.Title>My Assets</Styled.Title>
                                <Search
                                    name="ownAssets"
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                />
                            </Styled.Group>
                            <FilterButton onClick={handleOwnAssetFilterClick}>
                                Filter
                            </FilterButton>
                        </Styled.Header>
                        {isLoading && (
                            <Styled.LoadingAssetContainer>
                                {[1, 2, 3, 4].map((key) => (
                                    <Asset key={key} isLoading={isLoading} />
                                ))}
                            </Styled.LoadingAssetContainer>
                        )}
                        {!isLoading && (
                            <Styled.AssetContainer>
                                {ownAssetData.length > 0 ? (
                                    <Carousel
                                        swipeable={true}
                                        draggable={false}
                                        infinite={false}
                                        keyBoardControl={true}
                                        responsive={carouselBreakpoints}
                                        shouldResetAutoplay={false}
                                        autoPlay={false}
                                        removeArrowOnDeviceType={[
                                            'tabletSmall'
                                        ]}
                                        itemClass="carousel-item-margin"
                                    >
                                        {ownAssetData
                                            .filter(
                                                (item) =>
                                                    filteredOwnAssets.length ===
                                                        0 ||
                                                    filteredOwnAssets.includes(
                                                        item.trait_name.toLowerCase()
                                                    )
                                            )
                                            .filter(
                                                (item) =>
                                                    searchValue.ownAssets ===
                                                        '' ||
                                                    item.trait_name
                                                        .toLowerCase()
                                                        .includes(
                                                            searchValue.ownAssets.toLowerCase()
                                                        ) ||
                                                    item.value
                                                        .toLowerCase()
                                                        .includes(
                                                            searchValue.ownAssets.toLowerCase()
                                                        )
                                            )
                                            .map((item) => (
                                                <Asset
                                                    key={item.onChainId}
                                                    asset={item}
                                                    onClick={
                                                        handleOwnAssetClick
                                                    }
                                                    dropEndHandler={
                                                        dropEndHandler
                                                    }
                                                />
                                            ))}
                                    </Carousel>
                                ) : (
                                    'No Assets Found'
                                )}
                            </Styled.AssetContainer>
                        )}
                    </Styled.OwnAssetsContainer>
                </Styled.Content>
            </Styled.Main>
        </Styled.Container>
    );
};

export default MarketplaceDesktop;
