import React, { useRef } from 'react';
import * as Styled from './styled.components';
import { debounce } from 'lodash';
import { icons } from '../../utils/constants/icons';

const Search = (props) => {
    const inputRef = useRef();
    const onChangeHandler = (e) => {
        if (inputRef.current) {
            const { name, value } = inputRef.current;
            props.setSearchValue({ ...props.searchValue, [name]: value });
        }
    };
    const debouncedOnChangeHandler = debounce(onChangeHandler, 300);
    return (
        <Styled.Container fullWidth={props.fullWidth}>
            <Styled.LeftIcon>{icons.searchIcon}</Styled.LeftIcon>
            <Styled.InputField
                type="text"
                id={props.name}
                name={props.name}
                placeholder="Search"
                autoComplete="off"
                onChange={debouncedOnChangeHandler}
                ref={inputRef}
            />
            {props.rightIcon && (
                <Styled.RightIcon>{props.rightIcon}</Styled.RightIcon>
            )}
        </Styled.Container>
    );
};

export default Search;
