import React from 'react';
import { MintNonModal, Footer } from '../components';
import { env } from '../env';

/* eslint-disable eqeqeq */
const Mint = () => {
    return (
        <>
            {env.REACT_APP_MARKETPLACE_ENABLED == 'true' ? (
                <MintNonModal />
            ) : (
                <></>
            )}
            <Footer />
        </>
    );
};
/* eslint-enable eqeqeq */

export default Mint;
