export const setOwnAssetsFilterLabels = (payload) => ({
    type: 'SET_OWN_ASSETS_LABELS',
    payload: payload
});

export const setNonOwnAssetsFilterLabels = (payload) => ({
    type: 'SET_NON_OWN_ASSETS_LABELS',
    payload: payload
});

export const setFilterType = (payload) => ({
    type: 'SET_FILTER_TYPE',
    payload: payload
});

export const setFilteredOwnAssets = (payload) => ({
    type: 'SET_FILTERED_OWN_ASSETS',
    payload: payload
});

export const setFilteredNonOwnAssets = (payload) => ({
    type: 'SET_FILTERED_NON_OWN_ASSETS',
    payload: payload
});
