import { ethers } from 'ethers';

import { tokenContractAbi } from '../utils/constants/tokenContractAbi';
import * as log from './loggingService';
import { env } from '../env';

const requestConnectWallet = async () => {
    if (!window.ethereum) {
        throw new Error('No web3 wallet found.');
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const result = await provider.send('eth_requestAccounts', []);
    log.debug(`wallet authorized: ${JSON.stringify(result)}`);

    const signer = await provider.getSigner();
    const network = await provider.getNetwork();

    const payload = {
        provider: provider,
        signer: signer,
        ownerAddress: await signer.getAddress(),
        network: network
    };

    return payload;
};

const getTokenContract = (signer) => {
    const abi = tokenContractAbi;
    const address = env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
    const contract = new ethers.Contract(address, abi, signer);
    return contract;
};

const fetchTokens = async (signer, tokenIds) => {
    const contract = getTokenContract(signer);

    const responses = await Promise.all(
        tokenIds.map(async (tokenId) => {
            const tokenUri = await contract.tokenURI(tokenId);
            const response = await fetch(tokenUri);
            const tokenMetadata = await response.json();
            return tokenMetadata;
        })
    );

    return responses;
};

const mint = async (signer, address, quantity) => {
    const transferEvent = ethers.utils.id('Transfer(address,address,uint256)');
    const contract = getTokenContract(signer);
    const value = quantity * parseInt(env.REACT_APP_MINT_PRICE);

    try {
        const mintTxResponse = await contract.safeMint(address, quantity, {
            value: value.toString()
        });

        const mintTxReceipt = await mintTxResponse.wait();

        let tokenIds = [];
        try {
            for (let i = 0; i < mintTxReceipt.logs.length; i++) {
                if (mintTxReceipt.logs[i].topics[0] === transferEvent) {
                    log.debug(
                        `found transfer event, adding tokenId ${parseInt(
                            mintTxReceipt.logs[i].topics[3]
                        )}`
                    );
                    tokenIds.push(parseInt(mintTxReceipt.logs[i].topics[3]));
                }
            }
            return tokenIds;
        } catch (error) {
            log.error(
                `exception parsing tokenIds after successful mint, ${error}`
            );
        }

        return mintTxReceipt;
    } catch (error) {
        debugger;
        log.error(`error in web3Service.mint: ${error}`);
        // do error processing here
        // TODO: better than this
        throw new Error(`${error}`);
    }
};

const addNetwork = async (chainIdHex) => {
    if (!window.ethereum) {
        throw new Error('No web3 wallet found.');
    }

    try {
        log.debug(`target hex chain id: ${chainIdHex}`);

        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: chainIdHex,
                    rpcUrls: [env.REACT_APP_CHAIN_RPC_URL],
                    chainName: env.REACT_APP_CHAIN_NAME,
                    nativeCurrency: {
                        name: env.REACT_APP_CHAIN_SYMBOL,
                        symbol: env.REACT_APP_CHAIN_SYMBOL,
                        decimals: parseInt(env.REACT_APP_CHAIN_DECIMALS)
                    },
                    blockExplorerUrls: [env.REACT_APP_CHAIN_BLOCK_EXPLORER_URL]
                }
            ]
        });

        log.debug(`added chain`);
    } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask.
        log.error(`unexpected error adding network: ${error}`);
    }
};

const switchNetwork = async (chainIdHex) => {
    if (!window.ethereum) {
        throw new Error('No web3 wallet found.');
    }

    try {
        log.debug(`target hex chain id: ${chainIdHex}`);

        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [
                {
                    chainId: chainIdHex
                }
            ]
        });

        log.debug(`switched chain`);
    } catch (error) {
        if (error.code === 4902) {
            log.error(`target chain not available in wallet`);
            throw new Error('TargetChainNotInWallet');
        } else {
            log.error(`unexpected error switching networks: ${error}`);
            // todo: throw exception that the saga can catch so we can dispatch an action from the saga
        }
    }
};

export {
    requestConnectWallet,
    getTokenContract,
    mint,
    switchNetwork,
    addNetwork,
    fetchTokens
};
