import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { PolygonButton } from '../buttons';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { backgroundImages } from '../../utils/constants/images';
import { icons } from '../../utils/constants/icons';
import Sidebar from '../sidebar';
import * as Styled from './styled.components';
import { truncateStr } from '../../utils/helpers';
import {
    connectWalletRequest,
    logoutWalletRequest
} from '../../actions/web3Actions';

const Navbar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { pathname } = useLocation();
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 768px)' });
    const { isConnected, ownerAddress } = useSelector((state) => state.web3);
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();

    const toggleSiderbar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const connectHandler = () => {
        trackEvent({
            category: 'navbar',
            action: 'click-connect'
        });
        dispatch(connectWalletRequest());
    };

    const logoutHandler = () => {
        trackEvent({
            category: 'navbar',
            action: 'click-logout'
        });
        dispatch(logoutWalletRequest());
    };

    const handleDocsClick = () => {
        trackEvent({
            category: 'navbar',
            action: 'click-docs'
        });
    };

    if (isMobileOrTablet && sidebarOpen) {
        return <Sidebar toggleSiderbar={toggleSiderbar} />;
    }
    return (
        <Styled.NavContainer>
            <Styled.NavLogo>
                <Styled.NavLogoImg src={backgroundImages.logo} />
            </Styled.NavLogo>
            <Styled.NavItems>
                <Styled.NavItem
                    to="/"
                    className={pathname === '/' ? 'active' : ''}
                >
                    Home
                </Styled.NavItem>
                <Styled.NavAnchor
                    href="https://docs.zitherin.com"
                    target="_blank"
                    className={''}
                    onClick={handleDocsClick}
                >
                    Docs
                </Styled.NavAnchor>
                <Styled.NavAnchor
                    href="https://discord.gg/mD4YGpC2tE"
                    target="_blank"
                    className={''}
                >
                    Discord
                </Styled.NavAnchor>
                {/* <Styled.NavItem
          to="/marketplace"
          className={pathname === "/marketplace" ? "active" : ""}
        >
          Marketplace
        </Styled.NavItem>
        <Styled.NavItem
          to="/mint"
          className={pathname === "/mint" ? "active" : ""}
        >
          Mint
        </Styled.NavItem> */}
                {isConnected && (
                    <Styled.NavItem
                        to="/activities"
                        className={pathname === '/activities' ? 'active' : ''}
                    >
                        Activities
                    </Styled.NavItem>
                )}
            </Styled.NavItems>
            {!isConnected && (
                <Styled.NavBtnContainer>
                    <PolygonButton onClick={connectHandler}>
                        Connect Wallet
                    </PolygonButton>
                </Styled.NavBtnContainer>
            )}

            {isConnected && (
                <Styled.AvatarContainer>
                    <PolygonButton onClick={logoutHandler}>
                        <Styled.UserName>
                            {truncateStr(ownerAddress, 15)}
                        </Styled.UserName>
                    </PolygonButton>
                </Styled.AvatarContainer>
            )}
            <Styled.NavMenuIcon onClick={toggleSiderbar}>
                {icons.mobileMenu}
            </Styled.NavMenuIcon>
        </Styled.NavContainer>
    );
};

export default Navbar;
