import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

import reducers from './reducers';
import rootSaga from './sagas';
import App from './App';
import 'react-multi-carousel/lib/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { env } from './env';

const sagaMiddleware = createSagaMiddleware();

const reduxMiddleware = () => {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        return compose(
            applyMiddleware(sagaMiddleware),
            window.__REDUX_DEVTOOLS_EXTENSION__()
        );
    }

    return applyMiddleware(sagaMiddleware);
};

const store = createStore(reducers, reduxMiddleware());
sagaMiddleware.run(rootSaga);

const instance = createInstance({
    urlBase: env.REACT_APP_MATOMO_URL_BASE,
    siteId: env.REACT_APP_MATOMO_SITE_ID,
    trackerUrl: env.REACT_APP_MATOMO_TRACKER_URL, // optional, default value: `${urlBase}matomo.php`
    srcUrl: env.REACT_APP_MATOMO_SRC_URL, // optional, default value: `${urlBase}matomo.js`
    /* eslint-disable eqeqeq */
    disabled: env.REACT_APP_MATOMO_DISABLED == false ? true : false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    /* eslint-enable eqeqeq */
    heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: {
        // optional, default value: {}
        // any valid matomo configuration, all below are optional
        /* eslint-disable eqeqeq */
        disableCookies:
            env.REACT_APP_MATOMO_DISABLE_COOKIES == true ? true : false,
        setSecureCookie:
            env.REACT_APP_MATOMO_SET_SECURE_COOKIE == true ? true : false,
        /* eslint-enable eqeqeq */
        setRequestMethod: 'POST'
    }
});

const rootElement = document.getElementById('root');
ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <Router>
                <DndProvider backend={HTML5Backend}>
                    <MatomoProvider value={instance}>
                        <App />
                    </MatomoProvider>
                </DndProvider>
            </Router>
        </Provider>
    </StrictMode>,
    rootElement
);
