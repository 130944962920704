import styled from 'styled-components';

export const RoadmapContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 70%;
    margin-top: 5rem;
    margin-bottom: 3rem;
    padding-bottom: 5rem;
`;

export const RoadmapRow = styled.div`
    position: relative;
    padding: 1rem 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${({ left }) => (left ? 'flex-start' : 'flex-end')};

    @media only screen and (min-width: 1920px) {
        padding: 1rem 12rem;
    }
`;

export const RoadmapItem = styled.div`
    position: relative;
    background: #3b2a8b;
    width: 400px;
    border-radius: 1.75rem;
    padding: 2rem 2rem 2rem 4rem;
    text-align: left;

    @media only screen and (max-width: 1024px) {
        width: 325px;
    }
`;

export const RoadmapTitle = styled.h4`
    font-weight: 600;
    font-size: 1.25rem;
    color: #f5f5f5;
    margin-bottom: 0.75rem;

    @media only screen and (max-width: 1024px) {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
`;

export const RoadmapText = styled.p`
    color: #f5f5f5;
    font-weight: 200;
    font-size: 1rem;
    margin-bottom: 0.75rem;
    @media only screen and (max-width: 1024px) {
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
    }
`;

export const RoadmapSubTitle = styled.span`
    color: #e250e5;
    font-size: 1.125rem;
    font-weight: 600;
    @media only screen and (max-width: 1024px) {
        font-size: 1rem;
    }
`;

export const Link = styled.div`
    position: absolute;
    width: 50%;
    height: 100%;
    border: 2px dashed #bb50ff;
    background: transparent;
    align-self: flex-end;
    border-radius: 1.5rem;
    border-bottom: none;
    bottom: -40%;
    z-index: -1;
`;

export const LinkRight = styled(Link)`
    right: 25%;
    border-left: none;
`;

export const LinkLeft = styled(Link)`
    left: 25%;
    border-right: none;
`;

export const OutestCircle = styled.div`
    position: absolute;
    top: -20%;
    ${({ left }) => (left ? 'left: -10%;' : 'right:-10%;')}
    width: 105px;
    height: 105px;
    border-radius: 50%;
    background: rgba(83, 126, 237, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const OuterCircle = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(83, 126, 237, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InnerCircle = styled.div`
    width: 60px;
    height: 60px;
    padding: 0.75rem;
    border-radius: 50%;
    background: rgba(83, 126, 237, 1);
`;

export const Icon = styled.div`
    color: white;
`;

export const RoadmapMobileTitle = styled.h5`
    font-weight: 500;
    font-size: 1rem;
    color: #f5f5f5;
    margin-bottom: 0.75rem;
`;

export const RoadmapMobileText = styled.p`
    color: #f5f5f5;
    font-weight: 200;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
`;

export const RoadmapMobileSubtitle = styled.p`
    color: #e250e5;
    font-size: 1rem;
    font-weight: 500;
`;

export const RoadmapMobileContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        width: 0px;
        height: 90%;
        border: 2px dashed #bb50ff;
        background: transparent;
        left: 36px;
        z-index: -1;
    }

    @media only screen and (max-width: 280px) {
        &::after {
            height: 92%;
            top: 50px;
        }
    }
`;

export const RoadMapMobileItemWrapper = styled.div`
    width: 100%;
    position: relative;
    padding-left: 80px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const RoadMapMobileItem = styled.div`
    background: #392877;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 12px;
    width: 100%;
    position: relative;
    text-align: left;
`;

export const OutestMobileCircle = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background: rgba(83, 126, 237, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 25px;
`;

export const OuterMobileCircle = styled.div`
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: rgba(83, 126, 237, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InnerMobileCircle = styled.div`
    width: 37px;
    height: 37px;
    padding: 5px;
    border-radius: 50%;
    background: rgba(83, 126, 237, 1);
`;
