export const roadmapData = [
    {
        id: 1,
        title: 'Prototype',
        subtitle: 'Milestone 1',
        text: 'Build a prototype of NFTs with tradeable traits. Prove the technology works.',
        left: true,
        pending: false,
        completed: true
    },
    {
        id: 2,
        title: 'Launch Site',
        subtitle: 'Milestone 2',
        text: 'Take the prototype and run with it. Build the home for Zitherin.',
        left: false,
        pending: false,
        completed: true
    },
    {
        id: 3,
        title: 'Public Mint',
        subtitle: 'Milestone 3',
        text: 'Zitherin begins. Open minting of Generation 1 Heroes to the public.',
        left: true,
        inProgress: true,
        pending: false,
        completed: true
    },
    {
        id: 4,
        title: 'Tradeable Traits',
        subtitle: 'Milestone 4',
        text: 'Launch tradeable traits. Enable players to buy, sell, and trade the traits on their Gen 1 Heroes.',
        left: false,
        pending: true,
        completed: false
    },
    {
        id: 5,
        title: 'Dungeons',
        subtitle: 'Milestone 5',
        text: 'Launch Dungeons with randomized monsters, loot, and experiences. This milestone marks the first Play-2-Earn feature of Zitherin.',
        left: true,
        pending: true,
        completed: false
    },
    {
        id: 6,
        title: 'PvP Battle',
        subtitle: 'Milestone 6',
        text: 'You have minted your Hero. You have battled monsters in Dungeons. You have traded your gear to get the best in the game. Now, battle other Heroes in Arenas.',
        left: false,
        pending: true,
        completed: false
    },
    {
        id: 7,
        title: 'Mercenary Mode',
        subtitle: 'Milestone 7',
        text: 'Earn more passive income from your heroes.  Mercenary mode allows other players to hire your Heroes--earning you passive rewards, mercenary fees, and a piece of the spoils.',
        left: true,
        pending: true,
        completed: false
    },
    {
        id: 8,
        title: 'Crafting',
        subtitle: 'Milestone 8',
        text: 'Crafting introduces new equipment that goes beyond Dungeon and Arena rewards. Craft your own gear, upgrade, level, and expand your arsenal.',
        left: false,
        pending: true,
        completed: false
    },
    {
        id: 9,
        title: 'Beyond',
        subtitle: 'Milestone vNext',
        text: 'Zitherin is always expanding and ever evolving.',
        left: true,
        pending: true,
        completed: false
    }
];
