export const activities = [
  {
    hash: "1",
    date: new Date().toUTCString(),
    type: "Buy Asset",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "2",
    date: new Date().toUTCString(),
    type: "Mint",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "3",
    date: new Date().toUTCString(),
    type: "Claim Reward",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "4",
    date: new Date().toUTCString(),
    type: "Buy Asset",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "5",
    date: new Date().toUTCString(),
    type: "Mint",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "6",
    date: new Date().toUTCString(),
    type: "Claim Reward",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "7",
    date: new Date().toUTCString(),
    type: "Buy Asset",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "8",
    date: new Date().toUTCString(),
    type: "Mint",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "9",
    date: new Date().toUTCString(),
    type: "Claim Reward",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "10",
    date: new Date().toUTCString(),
    type: "Buy Asset",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "11",
    date: new Date().toUTCString(),
    type: "Mint",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "12",
    date: new Date().toUTCString(),
    type: "Claim Reward",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "13",
    date: new Date().toUTCString(),
    type: "Buy Asset",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "14",
    date: new Date().toUTCString(),
    type: "Mint",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "15",
    date: new Date().toUTCString(),
    type: "Claim Reward",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "16",
    date: new Date().toUTCString(),
    type: "Buy Asset",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "17",
    date: new Date().toUTCString(),
    type: "Mint",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "18",
    date: new Date().toUTCString(),
    type: "Claim Reward",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "19",
    date: new Date().toUTCString(),
    type: "Buy Asset",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "20",
    date: new Date().toUTCString(),
    type: "Mint",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "21",
    date: new Date().toUTCString(),
    type: "Claim Reward",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "22",
    date: new Date().toUTCString(),
    type: "Buy Asset",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "23",
    date: new Date().toUTCString(),
    type: "Mint",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
  {
    hash: "24",
    date: new Date().toUTCString(),
    type: "Claim Reward",
    method: "swapExactTokensForTokens",
    detail: "Labore fugiat magna adipisicing sit labore.",
  },
];
