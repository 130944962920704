import React from 'react';
import * as Styled from './styles';
import { icons } from '../../utils/constants/icons';
import { roadmapData } from '../../utils/constants/roadmap';

const LandingRoadmap = () => {
    return (
        <Styled.SectionThree>
            <Styled.HeroContainerTwo>
                <Styled.HeroTitleMedium>Roadmap</Styled.HeroTitleMedium>
            </Styled.HeroContainerTwo>
            <Styled.RoadmapContainer>
                {roadmapData.map((item) => (
                    <Styled.RoadmapRow key={item.id} left={item.left}>
                        <Styled.RoadmapItem>
                            <Styled.RoadmapTitle>
                                {item.title}
                            </Styled.RoadmapTitle>
                            <Styled.RoadmapText>{item.text}</Styled.RoadmapText>
                            <Styled.RoadmapSubTitle>
                                {item.subtitle}
                            </Styled.RoadmapSubTitle>
                            {!item.pending && (
                                <Styled.OutestCircle left={item.left}>
                                    <Styled.OuterCircle>
                                        <Styled.InnerCircle>
                                            <Styled.Icon>
                                                {item.inProgress
                                                    ? icons.hourglass
                                                    : icons.check}
                                            </Styled.Icon>
                                        </Styled.InnerCircle>
                                    </Styled.OuterCircle>
                                </Styled.OutestCircle>
                            )}
                        </Styled.RoadmapItem>
                        {roadmapData.length !== item.id ? (
                            item.left ? (
                                <Styled.LinkRight />
                            ) : (
                                <Styled.LinkLeft />
                            )
                        ) : (
                            <></>
                        )}
                    </Styled.RoadmapRow>
                ))}
            </Styled.RoadmapContainer>
        </Styled.SectionThree>
    );
};

export default LandingRoadmap;
