import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { icons } from '../../utils/constants/icons';
import { mintCharacters } from '../../mock';
import { backgroundImages } from '../../utils/constants/images';
import * as Styled from './styled.components';
import { env } from '../../env';
import {
    connectWalletRequest,
    mintRequest,
    clearMintError
} from '../../actions/web3Actions';
import { showToast } from '../../actions';

const MintNonModal = (props) => {
    const basePrice = env.REACT_APP_MINT_PRICE;
    const maxMints = env.REACT_APP_MAX_MINTS;
    const [counter, setCounter] = useState(1);
    const [counterText, setCounterText] = useState('Hero');
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 768px)' });
    const navigate = useNavigate();
    const { isConnected, signer, mintError, ownerAddress, isMinting } =
        useSelector((state) => state.web3);
    const { trackEvent } = useMatomo();
    const dispatch = useDispatch();

    const connectHandler = () => {
        trackEvent({
            category: 'mint',
            action: 'click-connect'
        });
        dispatch(connectWalletRequest());
    };

    const mintClickHandler = () => {
        if (isMinting) return;
        trackEvent({
            category: 'mint',
            action: 'mint-clicked',
            value: counter
        });
        dispatch(
            mintRequest({
                address: ownerAddress,
                quantity: counter,
                signer: signer
            })
        );
    };

    const increment = () => {
        if (isMinting) return;
        if (counter < maxMints) {
            setCounter((prev) => prev + 1);
        } else {
            dispatch(
                showToast(
                    `${maxMints} is the max allowed per mint transaction.`,
                    'error'
                )
            );
        }
    };
    const decrement = () => {
        if (isMinting) return;
        if (counter > 1) {
            setCounter((prev) => prev - 1);
        }
    };

    useEffect(() => {
        if (counter > 1) {
            setCounterText('Heroes');
        } else {
            setCounterText('Hero');
        }

        if (mintError !== '') {
            dispatch(showToast(mintError, 'error'));
            setTimeout(() => {
                dispatch(clearMintError());
            }, 500);
        }
    }, [setCounterText, counter, props, mintError, dispatch]);

    const goBack = () => navigate(-1);

    return (
        <Styled.Container>
            <Styled.NonModalWrapper>
                <Styled.ModalHeader>
                    {!isMobileOrTablet && (
                        <Styled.ActionButton onClick={goBack}>
                            <Styled.ActionIcon>
                                {icons.arrowLeft}
                            </Styled.ActionIcon>
                            Go back
                        </Styled.ActionButton>
                    )}
                    <Styled.TabHeader>
                        {isMobileOrTablet && (
                            <Styled.ActionIcon onClick={goBack}>
                                {icons.arrowLeft}
                            </Styled.ActionIcon>
                        )}
                        <Styled.TabHeaderText>Mint a Hero</Styled.TabHeaderText>
                    </Styled.TabHeader>
                </Styled.ModalHeader>
                <Styled.Body>
                    <Styled.AssetTitleContainer>
                        <Styled.AssetSubTitle>Mint</Styled.AssetSubTitle>
                        <Styled.AssetTitle>
                            1 Hero = {basePrice} Matic
                        </Styled.AssetTitle>
                        <Styled.AssetSubTitle>
                            excluding gas
                        </Styled.AssetSubTitle>
                    </Styled.AssetTitleContainer>

                    <Styled.IncrementContainer>
                        <Styled.IncrementIcon onClick={decrement}>
                            {icons.minus}
                        </Styled.IncrementIcon>
                        <Styled.IncrementCounter>
                            {counter} {counterText}
                        </Styled.IncrementCounter>
                        <Styled.IncrementIcon onClick={increment}>
                            {icons.plus}
                        </Styled.IncrementIcon>
                    </Styled.IncrementContainer>

                    <Styled.CharacterContainer>
                        {mintCharacters.map((item) => (
                            <Styled.CharacterCard key={item.id}>
                                <Styled.Character>
                                    <Styled.CharacterImg src={item.character} />
                                </Styled.Character>
                                <Styled.Overlay>
                                    <Styled.OverlayImg
                                        src={backgroundImages.tokenOverly}
                                    />
                                </Styled.Overlay>
                            </Styled.CharacterCard>
                        ))}
                    </Styled.CharacterContainer>

                    <Styled.AssetTitleContainer>
                        <Styled.AssetTitle>
                            Total = {counter * basePrice} Matic
                        </Styled.AssetTitle>
                        <Styled.AssetSubTitle>
                            excluding gas
                        </Styled.AssetSubTitle>
                    </Styled.AssetTitleContainer>
                    {isConnected ? (
                        <Styled.MintButton onClick={mintClickHandler}>
                            {isMinting ? <>Mint in progress...</> : <>Mint</>}
                        </Styled.MintButton>
                    ) : (
                        <Styled.MintButton onClick={connectHandler}>
                            Connect Wallet
                        </Styled.MintButton>
                    )}
                </Styled.Body>
            </Styled.NonModalWrapper>
        </Styled.Container>
    );
};

export default MintNonModal;
