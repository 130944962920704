import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDrop } from 'react-dnd';
import * as Styled from './styled.components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Token = ({ token, isLoading }) => {
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 768px)' });

    const [{ isOver }, drop] = useDrop(() => ({
        // The type (or types) to accept - strings or symbols
        accept: 'ASSET',
        // Props to collect
        collect: (monitor) => {
            return {
                isOver: monitor.isOver()
            };
        },
        drop: () => {
            return { token };
        }
    }));

    const getTokenImage = useCallback((token) => {
        const image = token.image;
        if (image) {
            if (image.startsWith('https://')) return image;
            else {
                if (image.startsWith('data:image')) return image;
                else return 'data:image/png;base64, ' + image;
            }
        }
        return null;
    }, []);

    const TokenType = isMobileOrTablet
        ? Styled.TokenMobile
        : Styled.TokenDesktop;
    const TokenLoadingType = isMobileOrTablet
        ? Styled.TokenLoadingMobile
        : Styled.TokenLoadingDesktop;

    if (isLoading) {
        return (
            <TokenLoadingType isLoading={isLoading}>
                <Skeleton
                    height={'100%'}
                    highlightColor={'#3b2a8b'}
                    baseColor={'#1b1444'}
                    enableAnimation={true}
                />
            </TokenLoadingType>
        );
    }
    return (
        <TokenType
            ref={drop}
            style={{
                opacity: isOver ? 0.4 : 1,
                transform: isOver ? 'scale(1.05)' : 'none'
            }}
        >
            <Styled.Character>
                <Styled.CharacterImg src={getTokenImage(token)} />
            </Styled.Character>
        </TokenType>
    );
};

export default Token;
