export const showToast = (message, type = 'info', duration = 4000) => {
    return {
        type: 'SHOW_TOAST',
        payload: {
            message,
            type,
            duration
        }
    };
};
