import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    openFilterModal,
    openNonOwnAssetModal,
    openOwnAssetModal,
    setFilterType,
    setNonOwnAssetModalData,
    setOwnAssetModalData
} from '../../../actions';
import * as Styled from './styled.components';
import { FilterButton, Search, Asset, Token } from '../../../components';

const MarketplaceMobile = ({
    isLoading,
    tokens,
    ownAssetData,
    nonOwnAssetData
}) => {
    const [searchValue, setSearchValue] = useState({
        ownAssets: '',
        nonOwnAssets: ''
    });
    const dispatch = useDispatch();
    const { filteredOwnAssets, filteredNonOwnAssets } = useSelector(
        (state) => state.filter
    );

    const handleNonOwnAssetClick = (data) => {
        dispatch(setNonOwnAssetModalData(data));
        dispatch(openNonOwnAssetModal());
    };

    const handleOwnAssetClick = (data) => {
        dispatch(setOwnAssetModalData(data));
        dispatch(openOwnAssetModal());
    };

    const handleOwnAssetFilterClick = () => {
        dispatch(setFilterType('OWN_ASSETS'));
        dispatch(openFilterModal());
    };

    const handleNonOwnAssetFilterClick = () => {
        dispatch(setFilterType('NON_OWN_ASSETS'));
        dispatch(openFilterModal());
    };

    return (
        <>
            <Styled.Container>
                {/* Non Owned Tokens */}
                <Styled.Title>Assets to Buy</Styled.Title>
                <Search
                    name="nonOwnAssets"
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    fullWidth={true}
                    rightIcon={
                        <FilterButton onClick={handleNonOwnAssetFilterClick} />
                    }
                />
                {isLoading && (
                    <Styled.LoadingAssetContainer>
                        {[1, 2, 3, 4].map((key) => (
                            <Asset key={key} isLoading={isLoading} />
                        ))}
                    </Styled.LoadingAssetContainer>
                )}
                {!isLoading && (
                    <Styled.AssetsContainer
                        empty={nonOwnAssetData.length === 0}
                    >
                        {nonOwnAssetData.length > 0
                            ? nonOwnAssetData
                                  .filter(
                                      (item) =>
                                          filteredNonOwnAssets.length === 0 ||
                                          filteredNonOwnAssets.includes(
                                              item.trait_name.toLowerCase()
                                          )
                                  )
                                  .filter(
                                      (item) =>
                                          searchValue.nonOwnAssets === '' ||
                                          item.trait_name
                                              .toLowerCase()
                                              .includes(
                                                  searchValue.nonOwnAssets.toLowerCase()
                                              ) ||
                                          item.value
                                              .toLowerCase()
                                              .includes(
                                                  searchValue.nonOwnAssets.toLowerCase()
                                              )
                                  )
                                  .map((asset) => (
                                      <Asset
                                          asset={asset}
                                          key={asset.id}
                                          onClick={handleNonOwnAssetClick}
                                      />
                                  ))
                            : 'No Assets Found'}
                    </Styled.AssetsContainer>
                )}

                {/* User Tokens */}

                <Styled.Title>Tokens</Styled.Title>
                {/* <Search fullWidth={true} rightIcon={<FilterButton />} /> */}
                {isLoading && (
                    <Styled.LoadingAssetContainer>
                        {[1, 2, 3, 4].map((key) => (
                            <Token key={key} isLoading={isLoading} />
                        ))}
                    </Styled.LoadingAssetContainer>
                )}
                {!isLoading && (
                    <Styled.AssetsContainer empty={tokens.length === 0}>
                        {tokens.length > 0
                            ? tokens.map((token) => (
                                  <Token token={token} key={token.id} />
                              ))
                            : 'No Tokens Found'}
                    </Styled.AssetsContainer>
                )}

                {/* My Assets */}

                <Styled.Title>My Assets</Styled.Title>
                <Search
                    name="ownAssets"
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    fullWidth={true}
                    rightIcon={
                        <FilterButton onClick={handleOwnAssetFilterClick} />
                    }
                />
                {isLoading && (
                    <Styled.LoadingAssetContainer>
                        {[1, 2, 3, 4].map((key) => (
                            <Asset key={key} isLoading={isLoading} />
                        ))}
                    </Styled.LoadingAssetContainer>
                )}
                {!isLoading && (
                    <Styled.AssetsContainer empty={ownAssetData.length === 0}>
                        {ownAssetData.length > 0
                            ? ownAssetData
                                  .filter(
                                      (item) =>
                                          filteredOwnAssets.length === 0 ||
                                          filteredOwnAssets.includes(
                                              item.trait_name.toLowerCase()
                                          )
                                  )
                                  .filter(
                                      (item) =>
                                          searchValue.ownAssets === '' ||
                                          item.trait_name
                                              .toLowerCase()
                                              .includes(
                                                  searchValue.ownAssets.toLowerCase()
                                              ) ||
                                          item.value
                                              .toLowerCase()
                                              .includes(
                                                  searchValue.ownAssets.toLowerCase()
                                              )
                                  )
                                  .map((asset) => (
                                      <Asset
                                          asset={asset}
                                          key={asset.id}
                                          onClick={handleOwnAssetClick}
                                      />
                                  ))
                            : 'No Assets Found'}
                    </Styled.AssetsContainer>
                )}
            </Styled.Container>
        </>
    );
};

export default MarketplaceMobile;
