import toast from 'react-hot-toast';

export function* showToastSaga(action) {
    const type = action.payload?.type;
    const duration = action.payload?.duration;
    const message = action.payload?.message;
    if (type.toLowerCase() === 'success') {
        yield toast.success(message, {
            duration: duration
        });
    } else if (type.toLowerCase() === 'error') {
        yield toast.error(message, {
            duration: duration
        });
    } else {
        yield toast(message, {
            duration: duration
        });
    }
}
