import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';

const toastStyles = {
    success: {
        style: {
            background: '#3b2a8b',
            color: '#FFFFFF'
        }
    },
    error: {
        style: {
            background: '#d1412e',
            color: '#FFFFFF'
        }
    }
};

const withToast = (Component) => (props) => {
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 768px)' });
    return (
        <>
            <Component {...props} />
            <Toaster
                position={isMobileOrTablet ? 'bottom-center' : 'bottom-left'}
                containerStyle={{ bottom: '5%' }}
                gutter={8}
                toastOptions={toastStyles}
            />
        </>
    );
};

export default withToast;
