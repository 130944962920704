import modalReducer from './modalReducer';
import activityReducer from './activityReducer';
import marketplaceReducer from './marketplaceReducer';
import filterReducer from './filterReducer';
import web3Reducer from './web3Reducer';

import { combineReducers } from 'redux';

const reducers = combineReducers({
    modal: modalReducer,
    activities: activityReducer,
    marketplace: marketplaceReducer,
    filter: filterReducer,
    web3: web3Reducer
});

export default reducers;
