import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowRightFilled } from '../../assets/icons/arrow-right-filled.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowLeftFilled } from '../../assets/icons/arrow-left-filled.svg';
import { ReactComponent as MobileMenu } from '../../assets/icons/menu.svg';
import { ReactComponent as Telegram } from '../../assets/icons/telegram.svg';
import { ReactComponent as Discord } from '../../assets/icons/discord.svg';
import { ReactComponent as Reddit } from '../../assets/icons/reddit.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as EthIcon } from '../../assets/icons/eth.svg';
import { ReactComponent as ArrowDoubleUp } from '../../assets/icons/arrow-double-up.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as GitbookIcon } from '../../assets/icons/gitbook-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as HourglassIcon } from '../../assets/icons/hourglass.svg';

export const icons = {
    add: <AddIcon />,
    arrowRight: <ArrowRight />,
    arrowRightFilled: <ArrowRightFilled />,
    arrowLeft: <ArrowLeft />,
    arrowLeftFilled: <ArrowLeftFilled />,
    mobileMenu: <MobileMenu />,
    telegram: <Telegram />,
    discord: <Discord />,
    twitter: <Twitter />,
    reddit: <Reddit />,
    closeIcon: <CloseIcon />,
    searchIcon: <SearchIcon />,
    settingsIcon: <SettingsIcon />,
    eth: <EthIcon />,
    plus: <Plus />,
    minus: <Minus />,
    arrowDoubleUp: <ArrowDoubleUp />,
    gitbook: <GitbookIcon />,
    check: <CheckIcon />,
    hourglass: <HourglassIcon />
};
