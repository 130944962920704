import { call, put } from 'redux-saga/effects';

import { trackEvent, pushInstruction } from '../services/matomoService';
import * as log from '../services/loggingService';
import {
    requestConnectWallet,
    mint,
    addNetwork,
    switchNetwork,
    fetchTokens
} from '../services/web3Service';
import { env } from '../env';

// function* accountChangedHandler() {
//     yield call(trackEvent, {
//         category: 'wallet',
//         action: 'wallet-account-changed'
//     });
//     // yield put({
//     //     type: 'CONNECT_WALLET_SUCCESS',
//     //     payload: payload
//     // });
// }

// function* networkChangedHandler() {
//     yield call(trackEvent, {
//         category: 'network',
//         action: 'network-changed'
//     });
//     yield put({
//         type: 'ADD_NETWORK_REQUEST'
//     });
// }

export function* connectWalletSaga() {
    log.debug(`web3saga.connectWalletSaga`);
    try {
        const payload = yield call(requestConnectWallet);

        yield call(pushInstruction, 'setUserId', payload.ownerAddress);

        if (
            parseInt(payload.network.chainId) !==
            parseInt(env.REACT_APP_CHAIN_ID_DECIMAL)
        ) {
            yield call(trackEvent, {
                category: 'wallet',
                action: 'connect-wallet-wrong-network'
            });
            yield put({
                type: 'ADD_NETWORK_REQUEST'
            });
        } else {
            yield call(trackEvent, {
                category: 'wallet',
                action: 'connect-wallet-success'
            });
            yield put({
                type: 'CONNECT_WALLET_SUCCESS',
                payload: payload
            });
        }
    } catch (error) {
        log.error(`web3saga.connectWalletSaga error: ${error}`);
        yield put({
            type: 'CONNECT_WALLET_FAILURE',
            payload: error
        });
        yield call(trackEvent, {
            category: 'wallet',
            action: 'connect-wallet-failure'
        });
    }
}

export function* addNetworkSaga() {
    debugger;
    log.debug(`web3saga.addNetworkSaga`);
    try {
        yield call(addNetwork, env.REACT_APP_CHAIN_ID_HEX);

        yield call(trackEvent, {
            category: 'network',
            action: 'network-add-success'
        });
        yield put({
            type: 'NETWORK_ADD_SUCCESS'
        });
    } catch (error) {
        log.error(`exception in addNetworkSaga: ${error}`);
        yield put({
            type: 'NETWORK_ADD_FAILURE',
            payload: error
        });
        yield call(trackEvent, {
            category: 'network',
            action: 'network-add-failure-unknown'
        });
    }
}

export function* switchNetworkSaga() {
    log.debug(`web3saga.switchNetworkSaga`);
    try {
        yield call(switchNetwork, env.REACT_APP_CHAIN_ID_HEX);

        yield call(trackEvent, {
            category: 'network',
            action: 'network-switch-success'
        });
        yield put({
            type: 'CONNECT_WALLET_REQUEST'
        });
    } catch (error) {
        log.error(`exception in switchNetworkSaga: ${error}`);

        if (error.message === 'TargetChainNotInWallet') {
            yield call(trackEvent, {
                category: 'network',
                action: 'network-switch-chain-not-in-wallet'
            });
            yield put({
                type: 'ADD_NETWORK_REQUEST'
            });
        }

        yield put({
            type: 'NETWORK_SWITCH_FAILURE',
            payload: error
        });
        yield call(trackEvent, {
            category: 'network',
            action: 'network-switch-failure-unknown'
        });
    }
}

export function* fetchMintedTokensSaga(payload) {
    log.debug(`web3saga.fetchMintedTokensSaga`);
    try {
        const tokens = yield call(
            fetchTokens,
            payload.payload.signer,
            payload.payload.tokenIds
        );
        return tokens;
    } catch (error) {
        log.error(`exception in fetchMintedTokensSaga: ${error}`);
    }
}

export function* mintSaga(payload) {
    log.debug(`web3saga.mintSaga`);
    try {
        const mintResult = yield call(
            mint,
            payload.payload.signer,
            payload.payload.address,
            payload.payload.quantity
        );

        if (mintResult.length) {
            yield put({
                type: 'FETCH_MINTED_TOKENS_REQUEST',
                payload: {
                    signer: payload.payload.signer,
                    tokenIds: mintResult
                }
            });
        }

        yield put({
            type: 'MINT_SUCCESS',
            payload: mintResult
        });

        yield call(trackEvent, {
            category: 'mint',
            action: 'mint-success',
            value: payload.quantity
        });
    } catch (error) {
        yield put({
            type: 'MINT_FAILURE',
            payload: error
        });
        yield call(trackEvent, {
            category: 'mint',
            action: 'mint-failure-unknown'
        });
    }
}
