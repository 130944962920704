import MatomoTracker from '@datapunt/matomo-tracker-js';

import { env } from '../env';
import * as log from './loggingService';

let _tracker = null;

const getTracker = () => {
    if (_tracker == null) {
        log.debug('tracker was null, newing up new tracker');

        try {
            _tracker = new MatomoTracker({
                urlBase: env.REACT_APP_MATOMO_URL_BASE,
                siteId: env.REACT_APP_MATOMO_SITE_ID,
                trackerUrl: env.REACT_APP_MATOMO_TRACKER_URL, // optional, default value: `${urlBase}matomo.php`
                srcUrl: env.REACT_APP_MATOMO_SRC_URL, // optional, default value: `${urlBase}matomo.js`
                /* eslint-disable eqeqeq */
                disabled: env.REACT_APP_MATOMO_DISABLED == false ? true : false, // optional, false by default. Makes all tracking calls no-ops if set to true.
                /* eslint-enable eqeqeq */
                heartBeat: {
                    // optional, enabled by default
                    active: true, // optional, default value: true
                    seconds: 10 // optional, default value: `15
                },
                linkTracking: false, // optional, default value: true
                configurations: {
                    // optional, default value: {}
                    // any valid matomo configuration, all below are optional
                    /* eslint-disable eqeqeq */
                    disableCookies:
                        env.REACT_APP_MATOMO_DISABLE_COOKIES == true
                            ? true
                            : false,
                    setSecureCookie:
                        env.REACT_APP_MATOMO_SET_SECURE_COOKIE == true
                            ? true
                            : false,
                    /* eslint-enable eqeqeq */
                    setRequestMethod: 'POST'
                }
            });
        } catch (error) {
            log.error(
                `error newing up matomo tracker: ${JSON.stringify(error)}`
            );
        }
    }

    return _tracker;
};

/*
{
  category: 'sample-page',
  action: 'click-event',
  name: 'test', // optional
  value: 123, // optional, numerical value
  documentTitle: 'Page title', // optional
  href: 'https://LINK.TO.PAGE', // optional
  customDimensions: [
    {
      id: 1,
      value: 'loggedIn',
    },
  ], // optional
}
*/
const trackEvent = (event) => {
    log.debug('trackEvent');
    //log.trace(JSON.stringify(event));
    const tracker = getTracker();

    try {
        tracker.trackEvent(event);
    } catch (error) {
        log.error(`error in trackEvent: ${JSON.stringify(error)}`);
    }
};

/*
name: string
args: string[]
*/
const pushInstruction = (name, args) => {
    log.debug('pushInstruction');
    //log.trace(`${name}: ${JSON.stringify(args)}`);
    const tracker = getTracker();

    try {
        tracker.pushInstruction(name, args);
    } catch (error) {
        log.error(`error in pushInstruction: ${JSON.stringify(error)}`);
    }
};

export { trackEvent, pushInstruction };
