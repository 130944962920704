import { env } from '../env';

const trace = (message) => {
    if (parseInt(env.REACT_APP_LOGLEVEL) === 0) {
        console.trace(message);
    }
};

const debug = (message) => {
    if (parseInt(env.REACT_APP_LOGLEVEL) <= 1) {
        console.debug(message);
    }
};

const info = (message) => {
    if (parseInt(env.REACT_APP_LOGLEVEL) <= 2) {
        console.info(message);
    }
};

const warn = (message) => {
    if (parseInt(env.REACT_APP_LOGLEVEL) <= 3) {
        console.warn(message);
    }
};

const error = (message) => {
    if (parseInt(env.REACT_APP_LOGLEVEL) <= 4) {
        console.error(message);
    }
};

export { trace, debug, info, warn, error };
