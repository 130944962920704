import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
    MarketplaceDesktop,
    MarketplaceMobile,
    OwnAssetModal,
    NonOwnAssetModal,
    FilterModal,
    Footer
} from '../components';
import axios from 'axios';
import 'react-loading-skeleton/dist/skeleton.css';
import * as log from '../services/loggingService';
import { env } from '../env';
import {
    setNonOwnAssetsFilterLabels,
    setOwnAssetsFilterLabels,
    showToast
} from '../actions';

const Marketplace = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [tokensData, setTokensData] = useState([]);
    const [ownAssetData, setOwnAssetsData] = useState([]);
    const [nonOwnAssetData, setNonOwnAssetData] = useState([]);
    const [selectedToken, setSelectedToken] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);

    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 768px)' });
    const modal = useSelector((state) => state.modal);
    const dispatch = useDispatch();

    const dropEndHandler = (asset, monitor) => {
        if (monitor.didDrop()) {
            const { token } = monitor.getDropResult();
            setSelectedAsset(asset);
            setSelectedToken(token);
            log.debug(
                `drop success; asset id: ${asset.id} token id: ${token.token_id}`
            );
        }
    };

    const fetchData = useCallback(
        async (endPoint) => {
            try {
                const data = await axios.get(
                    `${env.REACT_APP_API_URL}/${endPoint}`
                );
                return data.data;
            } catch (err) {
                log.debug(err);
                dispatch(
                    showToast(
                        'Error while fetch data for marketplace!',
                        'error'
                    )
                );
                return [];
            }
        },
        [dispatch]
    );

    useEffect(() => {
        Promise.all([
            fetchData('tokens/0x0000000000000000000000000000000000000000'),
            fetchData(
                'tokens/exclude/0x0000000000000000000000000000000000000000'
            )
        ]).then((results) => {
            const ownAssets = [];
            const nonOwnedAssets = [];
            const nonOwnedToken = results[1];
            const ownedTokens = results[0];
            let ownAssetsFilterOptions = [];
            let nonOwnAssetsFilterOptions = [];
            ownedTokens.forEach((token) => {
                ownAssets.push(...token.attributes);
            });
            nonOwnedToken.forEach((token) => {
                nonOwnedAssets.push(...token.attributes);
            });
            ownAssets.forEach((asset) => {
                ownAssetsFilterOptions.push(asset.trait_name);
            });
            nonOwnedAssets.forEach((asset) => {
                nonOwnAssetsFilterOptions.push(asset.trait_name);
            });
            ownAssetsFilterOptions = [...new Set(ownAssetsFilterOptions)];
            nonOwnAssetsFilterOptions = [...new Set(nonOwnAssetsFilterOptions)];

            setOwnAssetsData([...new Set(ownAssets)]);
            setNonOwnAssetData([...new Set(nonOwnedAssets)]);
            setTokensData(ownedTokens);
            dispatch(setNonOwnAssetsFilterLabels(nonOwnAssetsFilterOptions));
            dispatch(setOwnAssetsFilterLabels(ownAssetsFilterOptions));
            setIsLoading(false);
            dispatch(showToast('Data fetched successfully!', 'success'));
        });
    }, [dispatch, fetchData]);

    useEffect(() => {
        if (
            Boolean(env.REACT_APP_MARKETPLACE_ENABLED) === true &&
            selectedToken !== null &&
            selectedAsset !== null
        ) {
            const tokensCopy = tokensData.slice();
            const tokenIndex = tokensCopy.indexOf(selectedToken);
            let updatedData;
            log.debug(1);
            Promise.all([
                fetchData(
                    `preview/${parseInt(selectedToken.token_id)}/${parseInt(
                        selectedAsset.id
                    )}`
                )
            ]).then((results) => {
                updatedData = results[0];
                selectedToken.image = updatedData;
                tokensCopy[tokenIndex] = selectedToken;
                setTokensData(tokensCopy);
                setSelectedAsset(null);
                setSelectedToken(null);
            });
        }
    }, [selectedToken, fetchData, selectedAsset, tokensData]);
    /* eslint-enable eqeqeq */

    const MarketplaceType = isMobileOrTablet
        ? MarketplaceMobile
        : MarketplaceDesktop;

    return (
        <>
            <MarketplaceType
                isLoading={isLoading}
                ownAssetData={ownAssetData}
                nonOwnAssetData={nonOwnAssetData}
                tokens={tokensData}
                selectedToken={selectedToken}
                dropEndHandler={dropEndHandler}
            />
            {modal.isOwnAssetModalOpen && <OwnAssetModal />}
            {modal.isNonOwnAssetModalOpen && <NonOwnAssetModal />}
            {modal.isFilterModalOpen && <FilterModal />}
            <Footer />
        </>
    );
};

export default Marketplace;
